import type { Database } from 'o365.pwa.declaration.shared.dexie.objectStores.Database.d.ts';
import type { PWAState } from 'o365.pwa.declaration.shared.dexie.objectStores.PWAState.d.ts';
import type { ServiceWorkerState } from 'o365.pwa.declaration.shared.dexie.objectStores.ServiceWorkerState.d.ts';

declare module 'o365.pwa.declaration.shared.dexie.objectStores.App.d.ts' {
    export interface IDatabases {
        getAll: () => Promise<Array<Database>>;
    }

    export type Databases = IDatabases & Record<Exclude<string, keyof IDatabases>, Promise<Database | null>>;

    export class App {
        static objectStoreDexieSchema: string;

        id: string;
        debugUi?: boolean;
        title?: string;
        icon?: string;
        entrypoint?: string;

        constructor(id: string, title?: string, icon?: string, entrypoint?: string);

        databases: Databases;

        readonly pwaState: Promise<PWAState | null>;
        readonly serviceWorkerState: Promise<ServiceWorkerState | null>;

        readonly isAppInstalled: Promise<boolean>;

        save(): Promise<void>;
        delete(): Promise<void>;
        forceReload(): Promise<App | null>;
        initialize(): Promise<void>;
    }
}
